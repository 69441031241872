<template>
<div id="app" class="yo home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" page="yo" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #67ffd4 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="@/assets/blog6-logo-7.webp" alt="YO WhatsApp APK" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      YoWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>10.10</strong> | Size: <strong>80MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      YoWhatsApp is a popular mod of WhatsApp offering enhanced features and customizability beyond the official app's capabilities. Known for its user-friendly modifications, YoWhatsApp has released the latest version v10.10, packed with anti-ban protection and other sought-after features.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">Download YOWhatsApp
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> What is YoWhatsApp 2024?</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Download YoWhatsApp Apk v10.10 Latest Version</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Popular Features of YoWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> How to Update App With New Version?</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Download YoWa for PC</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Download YoWa for iPhone/iOS</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> WhatsApp VS YoWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Pros & Cons</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> Other Similar Mods of WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> Frequently Asked Questions (FAQs)</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> Conclusion</a>
                      </li>
                    </ul>

                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0"> What is YoWhatsApp 2025?
                  </span>
                </h2>
                <p>
                  The latest YoWhatsApp v10.10 offers a new and improved base update (v2.24.11.79) with robust anti-ban technology. Designed to work seamlessly with WhatsApp's core system, YoWhatsApp offers a distinct user experience without compromising security.
                </p>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/yo-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/yo-1.webp" alt="yowhatsapp screenshot" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <div class="gap"></div>
                <h3>Benefits of YoWhatsApp</h3>
                <p>
                  YoWhatsApp comes with powerful features such as privacy settings, customizable themes, and enhanced media capabilities. Users enjoy the flexibility to personalize their chats, control notifications, and extend video status lengths, among many other benefits.
                </p>
                <h3>User Reviews</h3>
                <p>
                  Users appreciate YoWhatsApp for its customization and unique features. Many reviews highlight the app’s smooth performance, the extensive library of themes, and the powerful privacy features as the main reasons for their preference.
                </p>

                <!-- <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp New</a>
                    </div>
                  </div> -->

                <h2 class="h">
                  <span id="TBC_1">Download YoWhatsApp APK v10.10 Latest Version</span>
                </h2>
                <p>
                  To start using YoWhatsApp without losing your old chats, follow these steps carefully.
                </p>

                <h3>
                  Steps to Install YoWhatsApp Without Losing Old Chats
                </h3>
                <ol>
                  <li><strong>1. Backup Chats: </strong>Open the official WhatsApp app, go to Settings > Chats > Chat Backup, and select "Backup."</li>
                  <li><strong>2. Download YoWhatsApp APK: </strong>Get the APK file from the official YoWhatsApp website.</li>
                  <li><strong>3. Enable Unknown Sources: </strong>Go to Settings > Security and enable "Unknown Sources."</li>
                  <li><strong>4. Install YoWhatsApp: </strong>Open the downloaded APK and follow the on-screen installation instructions.</li>
                  <li><strong>5. Restore Chats: </strong>After installation, open YoWhatsApp and select "Restore" to recover your backed-up chats.</li>
                </ol>

                <div class="gap"></div>
                <h3>
                  Requirements to Install the App
                </h3>
                <ul>
                  <li><strong>Android OS: </strong>Android 5.0 or higher</li>
                  <li><strong>Storage Space: </strong>At least 200 MB of free space</li>
                  <li><strong>Internet: </strong>Stable Wi-Fi or data connection to download and restore chats</li>
                  <li><strong>Root: </strong>No need.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_2">Popular Features of YoWhatsApp</span>
                </h2>

                <p>
                  YoWhatsApp introduces a variety of features designed to enhance the user experience:
                </p>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Use Emoji as Profile Picture</h3>
                    <p>
                      Set an emoji as your profile picture to add a fun twist.
                    </p>

                    <h3 class="h3">Scroll to the First Message</h3>
                    <p>
                      Easily navigate to the start of any conversation.
                    </p>

                    <h3 class="h3">Schedule Messages</h3>
                    <p>
                      Set specific messages to be sent at a future time automatically.
                    </p>

                    <h3 class="h3">Auto Reply</h3>
                    <p>
                      Customize auto-replies to keep contacts informed when you're away.
                    </p>

                    <h3 class="h3">YoThemes Section</h3>
                    <p>
                      Plenty of YoThemes are provided for a customized chat interface.
                    </p>

                    <h3 class="h3">Change Fonts</h3>
                    <p>
                      Personalize your text with different font styles.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Add Stickers</h3>
                    <p>
                      Choose from a variety of sticker packs for fun expressions.
                    </p>

                    <h3 class="h3">Who Can Call You</h3>
                    <p>
                      Select specific contacts allowed to call you on YoWhatsApp.
                    </p>

                    <h3 class="h3">Different App Icons</h3>
                    <p>
                      YoWhatsApp icon can also be changed as you like.
                    </p>

                    <h3 class="h3">Show Blue Tick After Reply</h3>
                    <p>
                      Control when the blue tick appears, allowing more privacy.
                    </p>

                    <h3 class="h3">Pin Up to 1000 Chats</h3>
                    <p>
                      Keep your favorite chats at the top, breaking the limits of the official WhatsApp.
                    </p>

                    <h3 class="h3">Set Video Status Up to 5 Minutes</h3>
                    <p>
                      Post longer video statuses to share more moments, a popular feature among WhatsApp Mods.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_3"> How to Update App With New Version
                  </span>
                </h2>
                <p>
                  To keep YoWhatsApp up-to-date, you'll need to download the latest APK from the official website. Here's how:
                </p>
                <ol>
                  <li>1. Visit the official YoWhatsApp website.</li>
                  <li>2. Download the latest YoWhatsApp APK (based on WhatsApp version 2.24.11.79).</li>
                  <li>3. Open the APK file and select "Install" to update without losing data.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_4"> Download YoWa for PC </span>
                </h2>
                <p>
                  Though YoWhatsApp is primarily an Android app, you can use it on a PC through an Android emulator like Bluestacks.
                </p>
                <ol>
                  <li>1. Install an Android emulator (e.g., Bluestacks) on your PC (Windows 7, 8, 10, or 11).</li>
                  <li>2. Download the YoWhatsApp APK on your PC.</li>
                  <li>3. Open the emulator, install the APK, and begin using YoWhatsApp on your PC.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_5">Download YoWa for iPhone/iOS</span>
                </h2>
                <p>
                  Currently, YoWhatsApp is not available for <a href="/gbwhatsapp-ios/" class="jump-url">iPhone</a> or iOS devices. As of now, it’s exclusive to Android, and an iOS version is not available.
                </p>

                <h2 class="h">
                  <span id="TBC_6"> WhatsApp vs. YoWhatsApp </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>WhatsApp</th>
                        <th>YoWhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>DND Mode</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Emoji as Profile Picture</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Schedule Messages</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Auto Reply</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Video Status (up to 5 mins)</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Theme Customization</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Hide Blue Ticks</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Pin Up to 1000 Chats</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Change App Icon</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Disable Forwarded Tag</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>App Lock</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>High Quality Images</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Status Download</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Hide Online Status</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_7"> Pros & Cons </span>
                </h2>
                <h3>
                  Pros
                </h3>
                <ul>
                  <li>Extensive customization options.</li>
                  <li>Enhanced privacy features.</li>
                  <li>Ability to schedule messages and set auto-replies.</li>
                </ul>

                <h3>
                  Cons
                </h3>
                <ul>
                  <li>Limited support on iOS devices.</li>
                  <li>Potential for account suspension if not updated regularly.</li>
                  <li>Not available on Google Play Store, requiring manual updates.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8"> Other Similar Mods of WhatsApp </span>
                </h2>
                <ul>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-gb`}" class="jump-url">GB WhatsApps</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-fm`}" class="jump-url">FM WhatsApps</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-plus`}" class="jump-url">WhatsApp Plus</router-link>
                  </li>
                  <li>OG WhatsApp</li>
                  <li>JT WhatsApp</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_9"> Frequently Asked Questions (FAQs)
                  </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is YoWhatsApp safe to use?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      YoWhatsApp includes anti-ban protection, but always download it from trusted sources to avoid security risks.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I use both WhatsApp and YoWhatsApp on the same phone?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, YoWhatsApp can be used alongside the official WhatsApp app by installing it with a different phone number.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_10"> Conclusion </span>
                </h2>

                <p>
                  YoWhatsApp v10.10 is ideal for users seeking more control, privacy, and customizability in their messaging experience. From unique themes to privacy-oriented options like scheduling messages and controlling blue ticks, YoWhatsApp delivers features the official app does not offer.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Privacy Policy</a>
            |
            <a href="/contact-us/">Contact Us</a>
            |
            <a href="/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="goto('download')">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
        lang: 'en',
        val: '🇺🇸 English'
      }],
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
